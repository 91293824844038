<template>
    <div>
        <card-header title="New Customer" icon="fa-plus"/>

        <card-body>
            <customer-form :customer="newCustomer"/>
        </card-body>

        <card-footer class="pt-3 pb-3 has-text-right">
            <b-button type="is-primary" size="is-small" :disabled="!newCustomer.first_name || !newCustomer.last_name || !newCustomer.email || !newCustomer.password" @click="submit"><i class="fas fa-plus mr-3"></i>New Customer</b-button>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from '../../TIER/components/CardHeader';
    import CardFooter from "../../TIER/components/CardFooter";
    import CardBody from "../../TIER/components/CardBody";

    import {client as http} from '../../http_client';
    import CustomerForm from "./CustomerForm";

    export default {
        props: ['card', 'props'],
        components: {CustomerForm, CardBody, CardFooter, CardHeader},
        data: function () {
            return {
                newCustomer: {}
            };
        },
        methods: {
            submit() {
                this.$emit('loading', true);
                http.post('/api/users/', this.newCustomer, {force: true}).then(response => {
                    this.$reloadCard('customers', {});
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {
                    console.log('An error occurred.');
                    this.$emit('loading', false);
                });
            },
        },
        mounted() {
            this.$saveAlert(true, 'Are you done creating this new customer? Unsaved changes will be lost.');
        }
    };
</script>
